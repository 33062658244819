import '../css/home1.css'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../images/gal-2.jpg';
import lawn1 from "../images//lawn1.jpg";
import lawn2 from "../images//lawn2.jpg";
import lawn3 from "../images//lawn4.jpeg";
import cateringServ from "../images//cateringServices.jpeg";
import quality from "../images//quality.webp";
import caters from "../images//cater.jpg";
import map from "../images//map.jpg";
import budget from "../images//budget.jpeg";
import celeb from "../images//bhadadeceleb.jpg";
import { Link, Outlet } from 'react-router-dom';
import Card from 'react-bootstrap/Card';



function Home1() {
  return (
    <>

      {/* <Container className='CaraMain' fluid>
        <Row className='core-1'>
          <Col className='core-1-1'>
            <Carousel data-bs-theme="dark" >
              <Carousel.Item className='ii'>
                <img src={couple_wed} alt="First slide" id='homeimg' />
                <Carousel.Caption>

                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img src={state_deco} alt="Second slide" id='homeimg' />
                <Carousel.Caption>

                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item>
                <img src={catering_serv} alt="Third slide" id='homeimg' />
                <Carousel.Caption>

                </Carousel.Caption>
              </Carousel.Item>

            </Carousel>
          </Col>
        </Row>
      </Container> */}




      <Container>
        <Row className='mainfirst'>
          <Col>
            <div className='firstdiv'>
              <h3 id='firstSilver'>Silver Package</h3>
              <h6 id='firstPack'>Package including Catering + Hall/Lawn</h6>
              <h6 id='firstPrice'>1,50,000 /- for 1000 Guests</h6>
              <Link to="silver"><button type="button" id='firstdivbtn' class="btn btn-warning">View More</button></Link>
            </div>
          </Col>


          <Col>
            <div className='firstdiv'>
              <h3 id='firstSilver'>Golden Package</h3>
              <h6 id='firstPack'>Package including Catering + Hall/Lawn</h6>
              <h6 id='firstPrice'>2,25,000 /- for 1000 Guests</h6>
              <Link to="golden"><button type="button" class="btn btn-warning" id='firstdivbtn'>View More</button></Link>


            </div>
          </Col>
        </Row>
      </Container>

      <Container className='CaterMain'>

        <Row>
          <Col>
            <h1>Welcome To</h1>
            <h1 style={{ color: "brown" }}>Bhadade Catering Services</h1>
            <div className='line'></div><br />
            <p id='para'>
              We are known for rendering services as per the requirements and within the allocated budget &
              assigned time period. We render our customers the latest ideas and concepts to maximize the
              functional aspect of all management services. With our proficiency in pre and the post service
              planning, we are capable to arrange perfectly for our esteemed clients. Moreover, our services
              are effective both in terms of result & economy as well as managed by professionals having an
              eye for perfection.
            </p>
            <br />
            <Link to="about1"><button type="button" class="btn btn-warning" id='but'>View More</button></Link>
          </Col>
          <Col>
            <img src={cateringServ} className='imgr' alt='' />

          </Col>
        </Row>
      </Container>


      <Container id='servicess' fluid>
        <Row>
          <Col id='serviceCol'>
            <img src={lawn1} />
            <h5>Shivshankar Lawn</h5>
          </Col>

          <Col id='serviceCol'>
            <img src={lawn2} />
            <h5>Shubham Lawn</h5>
          </Col>
        </Row>

        <Row>
          <Col id='serviceCol'>
            <img src={lawn3} />
            <h5>Gosawi Celebration</h5>
          </Col>
          <Col id='serviceCol'>
            <img src={celeb} />
            <h5 style={{ marginLeft: "-08%" }}>Kamlesh Bhadade Celebration</h5>
          </Col>
        </Row>
      </Container>


      <Container className='providedetail'>


        <Row id="providrow" style={{ height: "120px" }}>
          <Col class="provideone">

            <h1 style={{ color: "brown", margin: "30px auto" }}> Lawn and Hall for Multiple Events</h1>

          </Col>
        </Row>

        <Row id="providrow" style={{ height: "120px" }} >
          <Col class="provideone">


            <p style={{ display: "inline-block", fontSize: "30px", marginTop: "-30px", }}>Wedding Event</p>
            <ul id='funcc'>
              <li style={{ fontSize: "20px" }} >Wedding Events</li>
              <li style={{ fontSize: "20px" }} >Reception parties</li>
              <li style={{ fontSize: "20px" }} >Engagement Events</li>
            </ul>

            <div className='line' style={{ marginLeft: "30px", width: "50%" }}></div>
          </Col>
        </Row>
        <br /><br />
        <Row id="providrow" style={{ height: "120px" }}>
          <Col class="provideone">

            <p style={{ display: "inline-block", fontSize: "30px" }}>Corporates Events</p>
            <ul id='funcc'>
              <li style={{ fontSize: "20px" }} id='func'>Annual function</li>
              <li style={{ fontSize: "20px" }} id='func'> Official Events</li>
              <li style={{ fontSize: "20px" }} id='func'>College Events</li>
            </ul>

            <div className='line' style={{ marginLeft: "30px", width: "50%" }}></div>
          </Col>
        </Row>
        <br />
        <br />
        <Row id="providrow" style={{ height: "120px" }}>
          <Col class="provideone">


            <p style={{ display: "inline-block", fontSize: "30px" ,marginTop:"30px"}}>Celebrations  </p>
            <ul id='funcc'>
              <li style={{ fontSize: "20px" }} id='func'>Birthday Celebration</li>
              <li style={{ fontSize: "20px" }} id='func'>Annivarsary party</li>

            </ul>

            <div className='line' style={{ marginLeft: "30px", width: "50%" }}></div>
          </Col>
        </Row>

        <div class="providetwo">
          <div className='guess'>
            <h1 style={{ marginLeft: "60px", marginTop: "30px" }}> 1 Lakh +</h1>
            <h6 style={{ marginLeft: "15%", fontFamily: "cursive" }}> Happy Guests</h6>
            <h1 style={{ marginLeft: "290px", marginTop: "-80px", float: "left" }}>2000 + </h1>
            <h6 style={{ marginLeft: "250px", fontFamily: "cursive", float: "left", marginTop: "-25px" }}> Memorable Programs</h6>
            <br />
            <div className='line' style={{ marginLeft: "100px", width: "60%", backgroundColor: "black" }}></div>
            <div>
              <h1 style={{ marginLeft: "70px", marginTop: "30px" }}>150 + </h1>
              <h6 style={{ marginLeft: "12%", fontFamily: "cursive" }}> Employees in Work </h6>
              <h1 style={{ marginLeft: "310px", marginTop: "-80px", float: "left" }}>12  </h1>
              <h6 style={{ marginLeft: "270px", fontFamily: "cursive", float: "left", marginTop: "-25px" }}>Sucessful Years</h6>
            </div>

          </div>


          <div>
            <img src={logo} className='backimg' alt=''></img>
          </div>
        </div>
      </Container>

      <Container id='ExploreMain'>

        <Row>
          <h1 id='hotext'>Explore Bhadade's Group </h1>


          <Col>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src={budget} id='exploreImg' />
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>Lawn and Hall in Lowest Budget</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src={caters} id='exploreImg' />
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>Best Catering Service in Nagpur</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src={map} id='exploreImg' />
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>Best Location Near Main Road</Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{ width: '18rem' }}>
              <Card.Img variant="top" src={quality} id='exploreImg' />
              <Card.Body>
                <Card.Title style={{ textAlign: "center" }}>Quality Services</Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Home1;